<template lang="pug">
.all-notes
  h2.mb-2.subtitle Todas las notas
  .scroll-area
    notes-list(:notes='notes')
</template>

<script>
import NotesList from './NotesList.vue'

export default {
  components: { NotesList },
  props: {
    notes: { type: Array }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables/sizes';

.all-notes {
  padding: $spacing-constant * 2;
  background: white;
  width: 500px;

  @media only screen and (max-width: 768px) {
    width: initial;
  }
}

.scroll-area {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
